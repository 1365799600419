import { useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useForm from '../../hooks/useForm'
import FormFieldText from '../../components/Form/FormFieldText'
import { checkCode, checkPhone } from '../../utils/api'
import { useGetUserQuery } from '../../features/backend/backendApiSlice'
import { useSelector } from 'react-redux'
import { selectCartItemsTotalQty } from '../../features/cartItems/cartItemsSlice'

const AuthFormStep2 = ({ active, setStep, phone, phoneCode, phoneNumber, firstName }) => {
  const navigate = useNavigate()
  const { data: user, refetch } = useGetUserQuery()
  const cartItemsTotalQty = useSelector(selectCartItemsTotalQty)

  const timeout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const {
    fields,
    invalidFields,
    validateFieldMemoized,
    setFieldMemoized,
    setFieldMultiple,
    handleSubmit,
    isSubmitting,
    error,
  } = useForm({
    
  }, {
    smsCode: (value) => value?.length < 5,
  }, async (fields, setError) => {
    let mCRMPromocode = ''
    let utms = localStorage.getItem('utms')
    if (utms) {
      utms = JSON.parse(utms)
      let utm_campaign = utms?.find(utm => utm.tag == 'utm_campaign')
      if (utm_campaign) {
        mCRMPromocode = utm_campaign.value
      }
    }

    const response = await checkCode(phone, phoneCode, phoneNumber, fields.smsCode, firstName, mCRMPromocode)
    if (response?.error) {
      setError(response.error)
    } else if (response?.token) {
      localStorage.setItem('token', response.token)
      localStorage.setItem('token_mcrm', response.token_mcrm)
      await refetch()
      console.log(user)
      if (cartItemsTotalQty > 0) {
        navigate('/cart')
      } else {
        navigate('/me')
      }
      console.log(response)
    }
  })

  const [seconds, setSeconds] = useState(59)

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    }
  }, [seconds])

  const [isResending, setIsResending] = useState(false)

  const handleResend = async () => {
    if (isResending) return

    setIsResending(true)
    const response = await checkPhone(phone)
    setIsResending(false)

    if (response?.error) {
      setError('Произошла ошибка при отправке SMS-кода')
    } else if (response?.success === true) {
      setSeconds(59)
    }
  }

  const maskedPhone = phone.slice(0, -7) + '*****' + phone.slice(-2)

  return (
    <div className={`auth-form-container ${!active ? 'display-none' : ''}`}>
      <div className="auth-form">
        <div className="auth-form-heading">Подтверждение</div>
        <p>Проверочный код был отправлен на номер</p>
        <p><strong>{maskedPhone}</strong></p>
        <form onSubmit={handleSubmit} className="mb-15">
          {error && <div className="form-error">{error}</div>}
          <div className="form-fields-container-1col">
            <FormFieldText
              value={fields?.smsCode ?? ''}
              isInvalid={invalidFields.smsCode}
              placeholder={'Проверочный код'}
              onChange={setFieldMemoized('smsCode')}
              validateField={validateFieldMemoized('smsCode')}
            />
            <button type="submit" className={`button-big-primary-wide ${isSubmitting ? 'disabled' : ''}`}>
              {isSubmitting ? 'Загрузка...' : 'Подтвердить'}
            </button>
          </div>
        </form>
        {seconds > 0
          ? <p className="gray-text">Повторная отправка возможна через 0:{seconds < 10 ? `0${seconds}` : seconds}</p>
          : (isResending ? <p className="gray-text">Загрузка...</p> : <p className="link-action" onClick={handleResend}>Отправить код ещё раз</p>)
        }
        <p className="gray-text">или</p>
        <p className="link-action" onClick={() => setStep(1)}>Указать другой номер телефона</p>
      </div>
    </div>
  )
}

export default AuthFormStep2