import { useGetPagesKnowledgeQuery } from '../features/backend/backendApiSlice'
import { Link, useParams } from 'react-router-dom'
import useTitle from "../hooks/useTitle"
import useDescription from "../hooks/useDescription"

const KnowledgeOneView = () => {
  const { data, isError, isLoading, isSuccess } = useGetPagesKnowledgeQuery()
  
  const { slug } = useParams()

  const page = data ? data.find((page) => page.slug == slug) : null

  useTitle(page?.title)
  useDescription(page?.meta_description)

  return (
    <div className="container">
      <div className="heading-h1">{page?.title}</div>
      <div className="tabs-container">
        <div className="tabs">
          <Link to="/knowledge" className="link-action-back">
            <img src="/images/utils/back.svg" alt="" />
            <div>Назад ко всем знаниям</div>
          </Link>
        </div>
        <div className="tabs-content">
          {isError && <div>Произошла ошибка при загрузке статьи</div>}
          {isLoading && <div className="loading-rocket"><img src="/images/loading-rocket.svg" alt="" /></div>}
          {isSuccess && <div dangerouslySetInnerHTML={{ __html: page?.content }}></div>}
        </div>
      </div>
    </div>
  )
}

export default KnowledgeOneView