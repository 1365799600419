import { useState, useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useGetProductsQuery, useGetUserQuery, useGetUserMcrmQuery, useGetSettingsBySlugsQuery } from '../features/backend/backendApiSlice'
import {
  cartItemsCleared,
  cartItemUpdatedVariation,
  selectCartItems,
  selectCartItemsTotalSum,
  selectCartItemsTotalQty,
  selectCartItemsTotalWeight,
  cartItemAdded,
  cartItemRemoved,
} from '../features/cartItems/cartItemsSlice'
import { lastOrderSet, selectLastOrder } from "../features/lastOrder/lastOrderSlice"
import useCartForm from '../hooks/useCartForm'
import { Link } from 'react-router-dom'
import FormFieldText from '../components/Form/FormFieldText'
import FormFieldPhone from '../components/Form/FormFieldPhone'
import CartFormFieldCertificate from '../components/Cart/CartForm/CartFormFieldCertificate'
import CartFormFieldCoupon from '../components/Cart/CartForm/CartFormFieldCoupon'
import FormFieldCheckbox from '../components/Form/FormFieldCheckbox'
import FormFieldTextarea from '../components/Form/FormFieldTextarea'
import CartFormModalCity from '../components/Cart/CartForm/CartFormModalCity'
import CartFormModalAddresses from '../components/Cart/CartForm/CartFormModalAddresses'
import CartFormDeliveryType from '../components/Cart/CartForm/CartFormDeliveryType'
import CartFormFieldAddress from '../components/Cart/CartForm/CartFormFieldAddress'
import CartFormDeliveryDate from '../components/Cart/CartForm/CartFormDeliveryDate'
import CartFormDeliveryTime from '../components/Cart/CartForm/CartFormDeliveryTime'
import CartFormPaymentType from '../components/Cart/CartForm/CartFormPaymentType'
import CartFormFieldModalTrigger from '../components/Cart/CartForm/CartFormFieldModalTrigger'
import CartFormModalPickupStore from '../components/Cart/CartForm/CartFormModalPickupStore'
import CartFormModalPickupYandex from '../components/Cart/CartForm/CartFormModalPickupYandex'
import CartFormModalPickupCdek from '../components/Cart/CartForm/CartFormModalPickupCdek'
import CartItem from '../components/Cart/CartItem'
import { DELIVERY_TYPES, PAYMENT_TYPES, SLUG_SETTING_RESTRICTED_DATES, SLUG_CAT_COFFEE_DRIPS, PRODUCT_ID_FREE_DRIP_BAG } from '../utils/enums'
import { getDeliveryPriceYandex } from '../utils/api'
import {
  getMinDeliveryDateCourierDalli,
  getMinDeliveryDatePickupStore,
  getMinDeliveryDateCdek,
  getMinDeliveryDateYandex,
  getMinDeliveryDateRussianPost,
} from '../utils/functions'
import useTitle from "../hooks/useTitle"
import { isOnSale } from '../utils/functions'

const CartView = () => {
  useTitle('Корзина')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { data: products, refetch: refetchProducts, isFetching: isFetchingProducts } = useGetProductsQuery()
  const { data: user, isLoading: isLoadingUser } = useGetUserQuery()
  const { data: userMcrm, refetch: refetchUserMcrm } = useGetUserMcrmQuery()

  const onFocus = () => {
    refetchProducts()
  }

  useEffect(() => {
    window.addEventListener("focus", onFocus)
    return () => {
      window.removeEventListener("focus", onFocus)
    }
  }, [])

  // if product data has changed during refetch, we need to update it in the cartItems slice
  useEffect(() => {
    if (products && products.length > 0) {
      cartItems.forEach((item, index) => {
        const product = products.find(elem => elem.id == item.product_id)
        if (!product) {
          dispatch(cartItemRemoved({ product_id: item.product_id, variation: item.variation }))
          return
        }

        const variation = product.variations.find(elem => elem.id == item.variation.id)
        if (!variation) {
          dispatch(cartItemRemoved({ product_id: item.product_id, variation: item.variation }))
          return
        }

        dispatch(cartItemUpdatedVariation({ product_id: item.product_id, variation: variation }))
      })
    }
  }, [products])

  const cartItems = useSelector(selectCartItems)
  const cartItemsTotalQty = useSelector(selectCartItemsTotalQty)
  const cartItemsTotalSum = useSelector(selectCartItemsTotalSum)
  const cartItemsTotalWeight = useSelector(selectCartItemsTotalWeight)

  // adding a free bag for drips if drips qty is >= 15
  // if (products && products.length > 0) {
  //   let drips_qty = 0
  //   cartItems.forEach((item, index) => {
  //     const product = products.find(elem => elem.id == item.product_id)
  //     if (product && product.categories?.some(category => category.slug === SLUG_CAT_COFFEE_DRIPS)) {
  //       drips_qty += item.qty
  //     }
  //   })
  //   const freeDripBag = products.find(product => product.id == PRODUCT_ID_FREE_DRIP_BAG)
  //   if (freeDripBag) {
  //     if (drips_qty >= 15 && !cartItems.some(item => item.product_id == freeDripBag.id)) {
  //       dispatch(cartItemAdded({ product_id: freeDripBag.id, variation: freeDripBag.variations[0], qty: 1 }))
  //     }
  //     if (drips_qty < 15 && cartItems.some(item => item.product_id == freeDripBag.id)) {
  //       dispatch(cartItemRemoved({ product_id: freeDripBag.id, variation: freeDripBag.variations[0] }))
  //     }
  //   }
  // }

  if (isLoadingUser == false && !user?.id) {
    navigate('/login')
  }

  const {
    fields,
    invalidFields,
    validateFieldMemoized,
    setField,
    setFieldMemoized,
    setFieldMultiple,
    setCity,
    setAddress,
    submitOrder,

    deliveryPrice,
    deliveryPriceLoading,
    setDeliveryPrice,
    setDeliveryPriceLoading,

    deliveryPriceDalli,
    deliveryPriceCdekCourier,
    deliveryPriceYandexCourier,
    deliveryPriceYandexPickup,
    setDeliveryPriceYandexPickup,
    deliveryPriceCdekPickup,
    deliveryPriceRussianPost,

    deliveryTimeCdekCourierFrom,
    deliveryTimeCdekCourierTo,
    deliveryTimeYandexCourierFrom,
    deliveryTimeYandexCourierTo,
    deliveryTimeYandexPickupFrom,
    deliveryTimeYandexPickupTo,
    setDeliveryTimeYandexPickupFrom,
    setDeliveryTimeYandexPickupTo,
    deliveryTimeCdekPickupFrom,
    deliveryTimeCdekPickupTo,
    deliveryTimeRussianPostFrom,
    deliveryTimeRussianPostTo,
  } = useCartForm({
    phoneCode: "+7",
    phoneNumber: "___ ___ __ __",
    isForAnotherPerson: false,
    phoneCodeReceiver: "+7",
    phoneNumberReceiver: "___ ___ __ __",
    paymentType: PAYMENT_TYPES.CARD,
    bonusesDiscount: 0,
    acceptTerms: true,
  }, cartItemsTotalWeight, cartItemsTotalSum)

  const { data: settings } = useGetSettingsBySlugsQuery([SLUG_SETTING_RESTRICTED_DATES])
  const restrictedDates = settings && settings[SLUG_SETTING_RESTRICTED_DATES] ? settings[SLUG_SETTING_RESTRICTED_DATES].map(date => new Date(date)) : []

  console.log(user, userMcrm)

  useEffect(() => {
    if (user?.customer) {
      setFieldMultiple({
        name: user.customer.first_name,
        surname: user.customer.last_name,
        email: user.customer.email,
        phoneCode: user.customer.phone_code,
        phoneNumber: user.customer.phone_number,
        paymentWireOrg: user.customer.legal_org,
        paymentWireInn: user.customer.legal_inn,
        paymentWireKpp: user.customer.legal_kpp,
        paymentWireAcc: user.customer.legal_acc,
        paymentWireCorrAcc: user.customer.legal_corr_acc,
        paymentWireBik: user.customer.legal_bik,
      })
    }

    if (user?.customer?.addresses?.length > 0) {
      const defaultAddress = user.customer.addresses.find(elem => elem.is_default == 1)
      if (defaultAddress) {
        setAddress(defaultAddress)
      }
    }
  }, [user])

  const [modalOpen, setModalOpen] = useState(null)
  const closeModal = useCallback(() => setModalOpen(null), [])

  const calculateCartTotalSum = () => {
    let sum = cartItemsTotalSum

    if (deliveryPrice) {
      sum += deliveryPrice
    }

    if (fields?.couponDiscount) {
      sum -= fields.couponDiscount / 100
    }

    if (fields?.certificateBalance > 0) {
      if (sum >= fields.certificateBalance / 100) {
        if (fields?.certificateDiscount != fields.certificateBalance) {
          setField('certificateDiscount')(fields.certificateBalance)
        }

        sum -= fields.certificateBalance / 100
      } else {
        if (fields?.certificateDiscount != sum * 100) {
          setField('certificateDiscount')(sum * 100)
        }

        sum = 0
      }
    }

    if (fields?.bonusesDiscount > 0) {
      if (sum >= fields.bonusesDiscount) {
        sum -= fields.bonusesDiscount
      } else {
        if (fields?.bonusesDiscount != sum) {
          setField('bonusesDiscount')(sum)
        }

        sum = 0
      }
    }

    return sum
  }

  const cartTotal = calculateCartTotalSum()

  const calculateAvailableBonuses = () => {
    if (!userMcrm?.balance) {
      return 0
    }

    let cartTotalTemp = cartTotal
    if (fields?.bonusesDiscount > 0) {
      cartTotalTemp += parseInt(fields.bonusesDiscount)
    }
    if (userMcrm.balance.summ < Math.floor(cartTotalTemp)) {
      return userMcrm.balance.summ
    } else {
      return Math.floor(cartTotalTemp)
    }
  }

  useEffect(() => {
    setFieldMultiple({
      couponChecked: null,
      couponDiscount: 0,
    })
  }, [cartItems])

  const onOrderSuccess = (order) => {
    setField('isSubmitting')(false)
    dispatch(cartItemsCleared())
    refetchUserMcrm()

    dispatch(lastOrderSet(order))

    navigate('/thank-you')
  }

  const handleSubmit = async () => {
    const total = cartTotal
    console.log(total)
    const phone = (`${fields.phoneCode}${fields.phoneNumber}`).replaceAll(' ', '')
    const order = await submitOrder(cartItems, total)

    console.log(order)

    if (!order?.id) {
      setField('isSubmitting')(false)
      // alert('Произошла ошибка при оформлении заказа; пожалуйста, расскажите об этом нам по телефону или по электронной почте.')
      return
    }

    // Calltouch
    const ct_site_id = '62708'
    const ct_data = {             
      fio: `${fields.surname} ${fields.name}`,
      phoneNumber: phone,
      email: fields.email,
      subject: 'Заявка с сайта',
      // tags: 'Mercedes,Белый',
      // comment: 'Тест-драйв',
      // requestUrl: location.href,
      sessionId: window.ct('calltracking_params','10qg9fdf')?.sessionId 
    }
    fetch('https://api.calltouch.ru/calls-service/RestAPI/requests/' + ct_site_id + '/register/', {
      method: 'POST',
      // body: JSON.stringify(ct_data),
      body: Object.keys(ct_data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(ct_data[key])).join('&'),
      headers: {
        // 'Content-Type': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
    }).then(response => {
      console.log(response);
    })

    // Yandex.Metrika
    // eslint-disable-next-line
    ym(45440976, "reachGoal", "place-order");
    // eslint-disable-next-line
    ym(45440976, "reachGoal", "order-confirm");

    // Yandex.Ecommerce
    window.dataLayer.push({
      "ecommerce": {
        "currencyCode": "RUB",
        "purchase": {
          "actionField": {
            "id": order.id,
            "revenue": total,
          },
          "products": cartItems.map((item, index) => {
            const product = products.find(elem => elem.id == item.product_id)
            if (!product) {
              return
            }

            return {
              "id": product.id,
              "name": product.name,
              "price": isOnSale(item.variation) ? (item.variation.price_sale / 100) : (item.variation.price / 100),
              // "brand": product.brand,
              "category": product.categories.map(category => category.name).join(' / '),
              "variant": item.variation.options.map(option => option.name).join(' / '),
              "quantity": item.qty,
              // "list": "Корзина",
              "position": index + 1,
            }
          }),
        }
      }
    })

    if (fields.paymentType === PAYMENT_TYPES.WIRE || total === 0) {
      onOrderSuccess(order)
      return
    }

    // eslint-disable-next-line
    const widget = new cp.CloudPayments()

    widget.charge(
      {
        publicId: import.meta.env.VITE_CLOUDPAYMENTS_PUBLIC_ID,
        // accountId: `${result.id}_${Date.now()}`,
        invoiceId: order.id,
        description: "Оплата заказа",
        amount: total,
        currency: "RUB",
        email: fields.email,
        configuration: {
          paymentMethodsOrder: [
            cp.constant.ExternalPaymentMethods.SbpPay,
            cp.constant.ExternalPaymentMethods.MirPay,
            cp.constant.ExternalPaymentMethods.TinkoffPay,
          ]
        },
        data: {
          phone: phone,
          CloudPayments: {
            CustomerReceipt: {
              // Items: items,
              taxationSystem: 2, // система налогообложения; необязательный, если у вас одна система налогообложения
              email: fields.email, // e-mail покупателя, если нужно отправить письмо с чеком
              phone: phone, // телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
              isBso: false, // чек является бланком строгой отчетности
              amounts: {
                electronic: total, // Сумма оплаты электронными деньгами
                advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
                credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после запятой)
                provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
              },
            },
          },
        },
      },
      function (options) {
        // success

        console.log('success')
        console.log(options)

        onOrderSuccess(order)
      },
      function (reason, options) {
        // fail

        console.log('fail')
        console.log(reason, options)

        setField('isSubmitting')(false)
      },
      function (paymentResult, options) {
        // onComplete
        // Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
        // например вызов вашей аналитики Facebook Pixel
      }
    )
  }

  const onScroll = () => {
    let cartSummaryFooterSticky = document.querySelector('.cart-summary-footer-sticky')
    let cartSummaryProducts = document.querySelector('.cart-summary-products')

    let headerHeight = document.querySelector('.header').getBoundingClientRect().height
    let cartSummaryProductsBottom = cartSummaryProducts.getBoundingClientRect().bottom
    let cartSummaryFooterStickyTop = cartSummaryFooterSticky.getBoundingClientRect().top
    let agreementBottom = document.querySelector('.cart-form-agreement').getBoundingClientRect().top + document.querySelector('.cart-form-agreement').getBoundingClientRect().height
    
    // console.log(cartSummaryFooterStickyTop, cartSummaryProductsBottom, headerHeight, agreementBottom)

    if (cartSummaryProductsBottom < headerHeight) {
      if (!cartSummaryFooterSticky.classList.contains('cart-summary-footer-sticky-fixed')) { // If the cart summary footer is not fixed yet, we need to fix it
        cartSummaryFooterSticky.classList.add('cart-summary-footer-sticky-fixed');
        cartSummaryFooterSticky.style.width = cartSummaryProducts.getBoundingClientRect().width + 'px';
        cartSummaryFooterSticky.style.top = headerHeight + 'px';
      } else { // If the cart summary footer is already fixed, we need to check if it's not overlapping the recommended products section
        if (agreementBottom < (cartSummaryFooterSticky.getBoundingClientRect().height + headerHeight)) {
          cartSummaryFooterSticky.style.top = (agreementBottom - cartSummaryFooterSticky.getBoundingClientRect().height) + 'px';
        } else {
          cartSummaryFooterSticky.style.top = headerHeight + 'px';
        }
      }
    } else { // If the cart summary body bottom is below the header, we need to unfix the cart summary footer
      cartSummaryFooterSticky.classList.remove('cart-summary-footer-sticky-fixed')
      cartSummaryFooterSticky.style.width = 'auto'
    }
  }

  useEffect(() => {
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <>
      <CartFormModalCity
        isOpen={modalOpen == 'city'}
        onClose={closeModal}
        city={fields?.city ?? ''}
        setData={useCallback(setCity, [restrictedDates])}
        validateField={validateFieldMemoized('city')}
      />
      <CartFormModalAddresses
        isOpen={modalOpen == 'addresses'}
        onClose={closeModal}
        user={user}
        setAddress={useCallback(setAddress, [])}
      />
      <CartFormModalPickupStore
        isOpen={modalOpen == 'pickupStore'}
        onClose={closeModal}
        onSelect={setFieldMemoized('pickupStore')}
        validateField={validateFieldMemoized('pickupStore')}
      />
      <CartFormModalPickupYandex
        isOpen={modalOpen == 'pickupYandex'}
        onClose={closeModal}
        onSelect={useCallback((value, destinationStationId) => {
          setFieldMultiple({
            pickupYandex: value,
            pickupYandexId: destinationStationId,
          })
        }, [])}
        query={useMemo(() => {
          return { city: fields?.city }
        }, [fields?.city])}
        validateField={validateFieldMemoized('pickupYandex')}
        setDeliveryPriceYandex={useCallback((destinationStationId) => {
          if (destinationStationId === false) {
            setDeliveryPriceYandexPickup(false)
            setDeliveryTimeYandexPickupFrom(false)
            setDeliveryTimeYandexPickupTo(false)
          } else {
            getDeliveryPriceYandex('self_pickup', '', destinationStationId, cartItemsTotalWeight).then((data) => {
              if (data.error) {
                setDeliveryPriceYandexPickup(false)
                setDeliveryTimeYandexPickupFrom(false)
                setDeliveryTimeYandexPickupTo(false)
              } else {
                setDeliveryPriceYandexPickup(data.price)
                setDeliveryTimeYandexPickupFrom(data.delivery_from)
                setDeliveryTimeYandexPickupTo(data.delivery_to)
              }
            })
          }
        }, [])}
      />
      <CartFormModalPickupCdek
        isOpen={modalOpen == 'pickupCdek'}
        onClose={closeModal}
        onSelect={useCallback((value, addressFull) => {
          setFieldMultiple({
            pickupCdek: value,
            pickupCdekId: addressFull,
          })
        }, [])}
        query={useMemo(() => {
          return { countryIsoCode: fields?.countryIsoCode, city: fields?.city, cityPostalCode: fields?.cityPostalCode }
        }, [fields?.countryIsoCode, fields?.city, fields?.cityPostalCode])}
        validateField={validateFieldMemoized('pickupCdek')}
      />

      <div className="container">
        <div className="heading-h1">Оформление заказа</div>
        {cartItems.length === 0 && <div className="cart-empty">Ваша корзина пуста</div>}
        <div className={`cart-container ${cartItems.length === 0 ? 'display-none' : ''}`}>
          <div className="cart-form">
            <div className="cart-form-section">
              <div className="cart-form-section-heading">
                <div className="cart-form-section-heading--name">Данные получателя</div>
              </div>
              <div className="form-fields-container-2col">
                <FormFieldText
                  value={fields?.surname ?? ''}
                  isInvalid={invalidFields.surname}
                  placeholder={'Фамилия'}
                  onChange={setFieldMemoized('surname')}
                  validateField={validateFieldMemoized('surname')}
                />
                <FormFieldText
                  value={fields?.name ?? ''}
                  isInvalid={invalidFields.name}
                  placeholder={'Имя'}
                  onChange={setFieldMemoized('name')}
                  validateField={validateFieldMemoized('name')}
                />
                <FormFieldPhone
                  phoneCode={fields?.phoneCode ?? ''}
                  phoneNumber={fields?.phoneNumber ?? ''}
                  isInvalid={invalidFields.phone}
                  setPhoneCode={useCallback((phoneCode, mask) => {
                    setFieldMultiple({
                      phoneCode: phoneCode,
                      phoneNumber: mask,
                    })
                  }, [])}
                  setPhoneNumber={setFieldMemoized('phoneNumber')}
                  validateField={validateFieldMemoized('phone')}
                />
                <FormFieldText
                  value={fields?.email ?? ''}
                  isInvalid={invalidFields.email}
                  placeholder={'Эл. почта'}
                  onChange={setFieldMemoized('email')}
                  validateField={validateFieldMemoized('email')}
                />
              </div>
            </div>
            <div className="cart-form-section">
              <div className="form-fields-container-1col">
                <FormFieldCheckbox
                  label="Заказать другому человеку"
                  active={fields?.isForAnotherPerson ?? false}
                  onChange={setFieldMemoized('isForAnotherPerson')}
                  isValid={!invalidFields?.isForAnotherPerson}
                />
              </div>
            </div>
            <div className={`cart-form-section ${fields?.isForAnotherPerson === true ? '' : 'display-none'}`}>
              {/* <div className="cart-form-section-heading">
                <div className="cart-form-section-heading--name">Данные получателя</div>
              </div> */}
              <div className="form-fields-container-2col">
                <FormFieldText
                  value={fields?.surnameReceiver ?? ''}
                  isInvalid={invalidFields.surnameReceiver}
                  placeholder={'Фамилия'}
                  onChange={setFieldMemoized('surnameReceiver')}
                  validateField={validateFieldMemoized('surnameReceiver')}
                />
                <FormFieldText
                  value={fields?.nameReceiver ?? ''}
                  isInvalid={invalidFields.nameReceiver}
                  placeholder={'Имя'}
                  onChange={setFieldMemoized('nameReceiver')}
                  validateField={validateFieldMemoized('nameReceiver')}
                />
                <FormFieldPhone
                  phoneCode={fields?.phoneCodeReceiver ?? ''}
                  phoneNumber={fields?.phoneNumberReceiver ?? ''}
                  isInvalid={invalidFields.phoneReceiver}
                  setPhoneCode={useCallback((phoneCode, mask) => {
                    setFieldMultiple({
                      phoneCodeReceiver: phoneCode,
                      phoneNumberReceiver: mask,
                    })
                  }, [])}
                  setPhoneNumber={setFieldMemoized('phoneNumberReceiver')}
                  validateField={validateFieldMemoized('phoneReceiver')}
                />
                <FormFieldText
                  value={fields?.emailReceiver ?? ''}
                  isInvalid={invalidFields.emailReceiver}
                  placeholder={'Эл. почта'}
                  onChange={setFieldMemoized('emailReceiver')}
                  validateField={validateFieldMemoized('emailReceiver')}
                />
              </div>
            </div>
            <div className="cart-form-section">
              <div className="cart-form-section-heading">
                <div className="cart-form-section-heading--name">Населенный пункт</div>
                {/* <div className="cart-form-delivery-city-wrapper" onClick={() => setModalOpen('city')}>
                  <div><img src="/images/utils/location-pin.svg" alt="" /></div>
                  <div className={`cart-form-delivery-city ${invalidFields.city && 'field-invalid'}`}>{fields?.city ?? 'Выберите город'}</div>
                </div> */}
              </div>
              <div className="cart-form-delivery-city-section">
                <div className="cart-form-delivery-city-section-data">
                  <div className="cart-form-delivery-city-section-data-city">{fields?.city ?? 'Выберите город'}</div>
                  {(fields?.region && fields?.region != fields?.city) && <div className="cart-form-delivery-city-section-data-region">{fields.region}</div>}
                </div>
                <div className={`cart-form-delivery-city-section-action ${invalidFields.city && 'field-invalid'}`} onClick={() => setModalOpen('city')}>Изменить город</div>
              </div>
            </div>

            <div className={`${fields?.city ? '' : 'display-none'}`}>
              <div className={`cart-form-section ${fields?.deliveryTypeVariants?.length > 0 ? '' : 'display-none'}`}>
                <div className="cart-form-section-heading">
                  <div className="cart-form-section-heading--name">Доставка</div>
                </div>
                <div className="form-fields-container-2col cart-form-delivery-types">
                  <CartFormDeliveryType
                    deliveryTypeVariants={fields?.deliveryTypeVariants ?? null}
                    deliveryType={fields?.deliveryType ?? null}
                    onChange={useCallback((type) => {
                      setField('deliveryType')(type)
                      setDeliveryPrice(null)
                      setDeliveryPriceLoading(null)
                    }, [])}
                    city={fields?.city ?? ''}
                    cartItemsTotalSum={cartItemsTotalSum}
                    deliveryPrices={{
                      [DELIVERY_TYPES.COURIER_DALLI]: deliveryPriceDalli,
                      [DELIVERY_TYPES.COURIER_CDEK]: deliveryPriceCdekCourier,
                      [DELIVERY_TYPES.COURIER_YANDEX]: deliveryPriceYandexCourier,
                      [DELIVERY_TYPES.PICKUP_STORE]: 0,
                      [DELIVERY_TYPES.PICKUP_YANDEX]: deliveryPriceYandexPickup,
                      [DELIVERY_TYPES.PICKUP_CDEK]: deliveryPriceCdekPickup,
                      [DELIVERY_TYPES.RUSSIAN_POST]: deliveryPriceRussianPost,
                    }}
                    deliveryTimes={{
                      [DELIVERY_TYPES.COURIER_DALLI]: getMinDeliveryDateCourierDalli(restrictedDates).toLocaleDateString('ru-RU'),
                      [DELIVERY_TYPES.COURIER_CDEK]: deliveryTimeCdekCourierTo ? getMinDeliveryDateCdek(restrictedDates, deliveryTimeCdekCourierTo).toLocaleDateString('ru-RU') : null,
                      [DELIVERY_TYPES.COURIER_YANDEX]: deliveryTimeYandexCourierTo ? getMinDeliveryDateYandex(restrictedDates, deliveryTimeYandexCourierTo).toLocaleDateString('ru-RU') : null,
                      [DELIVERY_TYPES.PICKUP_STORE]: getMinDeliveryDatePickupStore(restrictedDates).toLocaleDateString('ru-RU'),
                      [DELIVERY_TYPES.PICKUP_YANDEX]: deliveryTimeYandexPickupTo ? getMinDeliveryDateYandex(restrictedDates, deliveryTimeYandexPickupTo).toLocaleDateString('ru-RU') : null,
                      [DELIVERY_TYPES.PICKUP_CDEK]: deliveryTimeCdekPickupTo ? getMinDeliveryDateCdek(restrictedDates, deliveryTimeCdekPickupTo).toLocaleDateString('ru-RU') : null,
                      [DELIVERY_TYPES.RUSSIAN_POST]: deliveryTimeRussianPostTo ? getMinDeliveryDateRussianPost(restrictedDates, deliveryTimeRussianPostTo).toLocaleDateString('ru-RU') : null,
                    }}
                  />
                </div>
              </div>
              <div className={`cart-form-section ${[ DELIVERY_TYPES.COURIER_DALLI ].includes(fields?.deliveryType) ? 'display-block' : 'display-none'}`}>
                <div className="cart-form-section-heading">
                  <div className="cart-form-section-heading--name">Дата и время доставки</div>
                </div>
                <div className="cart-form-pellets-container mb-15">
                  <CartFormDeliveryDate
                    deliveryDateVariants={fields?.deliveryDateVariants ?? null}
                    deliveryDate={fields?.deliveryDate ?? null}
                    onChange={setFieldMemoized('deliveryDate')}
                  />
                </div>
                <div className="cart-form-pellets-container">
                  <CartFormDeliveryTime
                    deliveryTimeVariants={fields?.deliveryTimeVariants ?? null}
                    deliveryTime={fields?.deliveryTime ?? null}
                    onChange={setFieldMemoized('deliveryTime')}
                  />
                </div>
              </div>
              <div className={`cart-form-section ${[ DELIVERY_TYPES.COURIER_DALLI, DELIVERY_TYPES.COURIER_CDEK, DELIVERY_TYPES.COURIER_YANDEX, DELIVERY_TYPES.RUSSIAN_POST ].includes(fields?.deliveryType) ? 'display-block' : 'display-none'}`}>
                <div className="cart-form-section-heading">
                  <div className="cart-form-section-heading--name">Адрес доставки</div>
                  <div className={`link-action ${!user?.id ? 'display-none': ''}`} onClick={() => setModalOpen('addresses')}>Изменить адрес доставки</div>
                </div>
                <div className="form-fields-container-1col mb-15">
                  <CartFormFieldAddress
                    cityFias={fields?.cityFias ?? ''}
                    address={fields?.address ?? ''}
                    onChange={setFieldMemoized('address')}
                    isInvalid={invalidFields.address}
                    validateField={validateFieldMemoized('address')}
                  />
                </div>
                <div className="form-fields-container-2col">
                  <FormFieldText
                    value={fields?.entrance ?? ''}
                    onChange={setFieldMemoized('entrance')}
                    placeholder={'Подъезд'}
                    isInvalid={false}
                  />
                  <FormFieldText
                    value={fields?.floor ?? ''}
                    onChange={setFieldMemoized('floor')}
                    placeholder={'Этаж'}
                    isInvalid={false}
                  />
                  <FormFieldText
                    value={fields?.apartment ?? ''}
                    onChange={setFieldMemoized('apartment')}
                    placeholder={'Квартира'}
                    isInvalid={false}
                  />
                  <FormFieldText
                    value={fields?.intercom ?? ''}
                    onChange={setFieldMemoized('intercom')}
                    placeholder={'Домофон'}
                    isInvalid={false}
                  />
                </div>
              </div>
              <div className={`cart-form-section ${[ DELIVERY_TYPES.PICKUP_STORE, DELIVERY_TYPES.PICKUP_YANDEX, DELIVERY_TYPES.PICKUP_CDEK ].includes(fields?.deliveryType) ? 'display-block' : 'display-none'}`}>
                <div className="cart-form-section-heading">
                  <div className="cart-form-section-heading--name">Пункт самовывоза</div>
                </div>
                <div className="form-fields-container-1col mb-15">
                  <CartFormFieldModalTrigger
                    label={
                      fields?.deliveryType == DELIVERY_TYPES.PICKUP_STORE ? (fields?.pickupStore ?? 'Выберите кофейню') :
                      fields?.deliveryType == DELIVERY_TYPES.PICKUP_YANDEX ? (fields?.pickupYandex ?? 'Выберите пункт самовывоза') :
                      fields?.deliveryType == DELIVERY_TYPES.PICKUP_CDEK ? (fields?.pickupCdek ?? 'Выберите пункт самовывоза') : ''
                    }
                    note="Пункты самовывоза на карте"
                    onClick={useCallback(() => setModalOpen(
                      fields?.deliveryType == DELIVERY_TYPES.PICKUP_STORE ? 'pickupStore' :
                      fields?.deliveryType == DELIVERY_TYPES.PICKUP_YANDEX ? 'pickupYandex' :
                      fields?.deliveryType == DELIVERY_TYPES.PICKUP_CDEK ? 'pickupCdek' : null
                    ), [fields?.deliveryType])}
                    isInvalid={
                      fields?.deliveryType == DELIVERY_TYPES.PICKUP_STORE ? invalidFields.pickupStore :
                      fields?.deliveryType == DELIVERY_TYPES.PICKUP_YANDEX ? invalidFields.pickupYandex :
                      fields?.deliveryType == DELIVERY_TYPES.PICKUP_CDEK ? invalidFields.pickupCdek : false
                    }
                  />
                </div>
              </div>
              <div className="cart-form-section">
                <div className="cart-form-section-heading">
                  <div className="cart-form-section-heading--name">Оплата</div>
                </div>
                <div className="form-fields-container-1col">
                  <CartFormPaymentType
                    paymentType={fields?.paymentType ?? null}
                    onChange={setFieldMemoized('paymentType')}
                  />
                  <div className={`form-fields-container-1col ${fields?.paymentType == PAYMENT_TYPES.WIRE ? 'display-flex' : 'display-none'}`}>
                    <FormFieldText
                      value={fields?.paymentWireOrg ?? ''}
                      onChange={setFieldMemoized('paymentWireOrg')}
                      placeholder={'Наименование организации'}
                      isInvalid={invalidFields.paymentWireOrg}
                      validateField={validateFieldMemoized('paymentWireOrg')}
                    />
                    <FormFieldText
                      value={fields?.paymentWireInn ?? ''}
                      onChange={setFieldMemoized('paymentWireInn')}
                      placeholder={'ИНН'}
                      isInvalid={invalidFields.paymentWireInn}
                      validateField={validateFieldMemoized('paymentWireInn')}
                    />
                    <FormFieldText
                      value={fields?.paymentWireKpp ?? ''}
                      onChange={setFieldMemoized('paymentWireKpp')}
                      placeholder={'КПП'}
                      isInvalid={invalidFields.paymentWireKpp}
                      validateField={validateFieldMemoized('paymentWireKpp')}
                    />
                    <FormFieldText
                      value={fields?.paymentWireAcc ?? ''}
                      onChange={setFieldMemoized('paymentWireAcc')}
                      placeholder={'Расчётный счёт'}
                      isInvalid={invalidFields.paymentWireAcc}
                      validateField={validateFieldMemoized('paymentWireAcc')}
                    />
                    <FormFieldText
                      value={fields?.paymentWireCorrAcc ?? ''}
                      onChange={setFieldMemoized('paymentWireCorrAcc')}
                      placeholder={'Корр. счёт'}
                      isInvalid={invalidFields.paymentWireCorrAcc}
                      validateField={validateFieldMemoized('paymentWireCorrAcc')}
                    />
                    <FormFieldText
                      value={fields?.paymentWireBik ?? ''}
                      onChange={setFieldMemoized('paymentWireBik')}
                      placeholder={'БИК'}
                      isInvalid={invalidFields.paymentWireBik}
                      validateField={validateFieldMemoized('paymentWireBik')}
                    />
                  </div>
                </div>
              </div>
              <div className={`cart-form-section ${!userMcrm?.balance ? 'display-none' : ''}`}>
                <div className="cart-form-section-heading">
                  <div className="cart-form-section-heading--name">Доступно для списания <span>{calculateAvailableBonuses()}</span> бонусов</div>
                </div>
                <div className="form-fields-container-1col">
                  <FormFieldText
                    value={fields?.bonusesDiscount ?? ''}
                    placeholder={'Бонусы'}
                    onChange={useCallback((value) => {
                      if (userMcrm?.balance?.summ > 0) {
                        if (value > userMcrm.balance.summ) {
                          setField('bonusesDiscount')(userMcrm.balance.summ)
                        } else {
                          setField('bonusesDiscount')(value)
                        }
                      }
                    }, [userMcrm?.balance?.summ])}
                    note={<div className="link-action" onClick={() => setField('bonusesDiscount')(calculateAvailableBonuses())}>Списать все бонусы</div>}
                  />
                </div>
              </div>
              <div className="cart-form-section">
                <div className="cart-form-section-heading">
                  <div className="cart-form-section-heading--name">{!user?.id ? 'Сертификат': 'Сертификат и Промокод'}</div>
                </div>
                <div className={!user?.id ? "form-fields-container-1col" : "form-fields-container-2col"}>
                  <CartFormFieldCertificate
                    value={fields?.certificate ?? ''}
                    checked={fields?.certificateChecked ?? ''}
                    onChange={setFieldMemoized('certificate')}
                    placeholder="Сертификат"
                    save={useCallback((code, balance) => {
                      setFieldMultiple({
                        certificateChecked: code,
                        certificateBalance: balance,
                      })
                    }, [])}
                  />
                  <CartFormFieldCoupon
                    value={fields?.coupon ?? ''}
                    checked={fields?.couponChecked ?? ''}
                    onChange={setFieldMemoized('coupon')}
                    placeholder="Промокод"
                    save={useCallback((code, discount) => {
                      setFieldMultiple({
                        couponChecked: code,
                        couponDiscount: discount,
                      })
                    }, [])}
                  />
                </div>
              </div>
              {/* <div className="cart-form-section">
                <div className="form-fields-container-1col">
                  <FormFieldCheckbox
                    label="Позвонить для подтверждения"
                    active={fields?.callForConfirmation ?? false}
                    onChange={setFieldMemoized('callForConfirmation')}
                    isValid={true}
                  />
                </div>
              </div> */}
              <div className="cart-form-section">
                <div className="cart-form-section-heading">
                  <div className="cart-form-section-heading--name">Комментарии</div>
                </div>
                <div className="form-fields-container-1col">
                  <FormFieldTextarea
                    value={fields?.comments ?? ''}
                    onChange={setFieldMemoized('comments')}
                    placeholder={'Оставьте комментарий к заказу'}
                  />
                </div>
              </div>
              <div className="cart-form-section cart-form-agreement">
                <div className="form-fields-container-1col">
                  <FormFieldCheckbox
                    label={useMemo(() => <>Я принимаю <Link to="/user-agreement">Пользовательское соглашение</Link></>, [])}
                    active={fields?.acceptTerms ?? false}
                    onChange={setFieldMemoized('acceptTerms')}
                    isValid={!invalidFields?.acceptTerms}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="cart-summary">
            {isFetchingProducts && <div className="cart-summary-overlay" />}
            <div className="cart-summary-header">
              <div>
                <div className="cart-summary-heading">Корзина</div>
                <div className="link-action" onClick={() => dispatch(cartItemsCleared())}>Очистить</div>
              </div>
            </div>
            <div className="cart-summary-products">
              {cartItems.map((item, index) => (
                <CartItem key={index} item={item} />
              ))}
            </div>
            <div className="cart-summary-footer-sticky">
              <div className="cart-summary-footer">
                <div className="cart-summary-footer-line">
                  <div>Товаров в корзине</div>
                  <div>{cartItemsTotalQty}</div>
                </div>
                <div className="cart-summary-footer-line gray">
                  <div>Стоимость доставки</div>
                  <div>{
                    deliveryPriceLoading === true ? 'Загрузка...' : (
                      deliveryPriceLoading === false ? 'Ошибка' : (
                        (deliveryPrice != null && typeof(deliveryPrice) != 'undefined') ? <>{deliveryPrice} <span className="commissioner-500">₽</span></> : `Выберите тип доставки`
                      )
                    )
                  }</div>
                </div>
                {fields?.couponChecked && <div className="cart-summary-footer-line discount">
                  <div>Скидка по промокоду</div>
                  <div>-{fields.couponDiscount / 100} <span className="commissioner-500">₽</span></div>
                </div>}
                {fields?.certificateChecked && <div className="cart-summary-footer-line discount">
                  <div>Скидка по сертификату</div>
                  <div>-{fields.certificateDiscount / 100} <span className="commissioner-500">₽</span></div>
                </div>}
                {fields?.bonusesDiscount > 0 && <div className="cart-summary-footer-line discount">
                  <div>Оплачено бонусами</div>
                  <div>-{fields.bonusesDiscount} <span className="commissioner-500">₽</span></div>
                </div>}
                <div className="cart-summary-footer-line total">
                  <div>Общая сумма</div>
                  <div>{cartTotal} <span className="commissioner-700">₽</span></div>
                </div>
              </div>
              <div className="cart-summary-actions">
                <div className="button-big-primary-wide" onClick={handleSubmit}>
                  {fields?.isSubmitting === true ? 'Оформляем...' : 'Оформить заказ'}
                </div>
              </div>
              {fields?.showErrors === true && <div className="cart-summary-alert danger">
                Пожалуйста заполните все необходимые поля и повторно нажмите кнопку "Оформить заказ"
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CartView