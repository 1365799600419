import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useGetUserQuery } from '../features/backend/backendApiSlice'
import { cartItemAdded } from '../features/cartItems/cartItemsSlice'
import { addProductToFavorites, removeProductFromFavorites } from '../utils/api'
import { isOnSale } from '../utils/functions'

const useProduct = (product) => {
  const { data: user, refetch } = useGetUserQuery()

  console.log(product)

  const dispatch = useDispatch()

  const attributes_used_for_variations = product.attributes.filter(attribute => attribute.is_used_for_variations == 1)

  // const sortedVariations = [...product.variations].sort((a, b) => {
  //   const aOptions = a.options.filter(option => attributes_used_for_variations.some(attribute => attribute.attribute_id === option.attribute_id))
  //   const bOptions = b.options.filter(option => attributes_used_for_variations.some(attribute => attribute.attribute_id === option.attribute_id))
  //   return aOptions.reduce((acc, option) => acc + option.id, '') > bOptions.reduce((acc, option) => acc + option.id, '') ? 1 : -1
  // })

  // TODO this should probably be done in some other cleaner way
  const sortedVariations = [...product.variations].sort((a, b) => {
    if (a.options.find(option => option.attribute_id == 1 && option.id == 1) && a.options.find(option => option.attribute_id == 2 && option.id == 3)) {
      return -1
    } else {
      return 0
    }
  })

  const images = product.variations.reduce((acc, variation) => {
    return [...acc, ...variation.images]
  }, [])

  const [variation, setVariation] = useState(sortedVariations[0])
  const [selectedOptions, setSelectedOptions] = useState(sortedVariations[0].options
    .filter(option => attributes_used_for_variations.some(attribute => attribute.attribute_id === option.attribute_id))
    .map(option => ({ attribute_id: option.attribute_id, name: option.name})))
  const [openedDropdown, setOpenedDropdown] = useState(null)

  const handleChangeOption = (attribute_id, name) => {
    const newOptions = selectedOptions.map(option => option.attribute_id === attribute_id ? { attribute_id, name } : option)
    setSelectedOptions(newOptions)
    const variation = product.variations.find(variation => variation.options.every(option => newOptions.some(newOption => newOption.attribute_id === option.attribute_id && newOption.name === option.name))) ?? false
    
    if (variation && variation?.in_stock == 1) {
      setVariation(variation)
    } else {
      setVariation(false)
    }
  }

  const [qty, setQty] = useState(1)

  const handleQtyLower = () => {
    if (qty > 1) {
      setQty(qty - 1)
    }
  }

  const handleQtyRaise = () => {
    setQty(qty + 1)
  }

  const [buttonFlashText, setButtonFlashText] = useState(false)

  const handleAddToCart = () => {
    if (variation && product.in_stock == 1 && product.is_published == 1) {
      dispatch(cartItemAdded({
        product_id: product.id,
        variation: variation,
        qty: qty,
        is_on_sale: isOnSale(variation),
      }))
      
      window.dataLayer.push({
        "ecommerce": {
          "currencyCode": "RUB",
          "add": {
            "products": [
              {
                "id": product.id,
                "name": product.title,
                "price": isOnSale(variation) ? (variation.price_sale / 100) : (variation.price / 100),
                // "brand": "Яндекс / Яndex",
                "category": product.categories.map(category => category.name).join(' / '),
                "variant": variation.options.map(option => option.name).join(' / '),
                "quantity": qty,
                // "list": "Одежда",
                // "position": 1
              },
            ]
          }
        }
      })

      setButtonFlashText(true)
      setTimeout(() => setButtonFlashText(false), 1000)
    }
  }

  const isFavorite = user?.customer?.products?.some(favorite => favorite.id === product.id)
  const [loadingIsFavorite, setLoadingIsFavorite] = useState(false)

  const handleAddToFavorites = async () => {
    setLoadingIsFavorite(true)
    await addProductToFavorites(product.id)
    await refetch()
    setLoadingIsFavorite(false)
  }

  const handleRemoveFromFavorites = async () => {
    setLoadingIsFavorite(true)
    await removeProductFromFavorites(product.id)
    await refetch()
    setLoadingIsFavorite(false)
  }

  return {
    images,
    variation,
    selectedOptions,
    openedDropdown,
    setOpenedDropdown,
    handleChangeOption,
    qty,
    handleQtyLower,
    handleQtyRaise,
    buttonFlashText,
    handleAddToCart,
    isFavorite,
    loadingIsFavorite,
    handleAddToFavorites,
    handleRemoveFromFavorites,
  }
}

export default useProduct