import FeaturedSlide from '../components/FeaturedSlide'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css'
import { useGetFeaturedProductsQuery } from '../features/backend/backendApiSlice'

const FeaturedSlider = () => {
  const { data } = useGetFeaturedProductsQuery()

  if (!data) {
    return null
  }

  return (
    <div className="featured-slider">
      <div className="styled-splide-container">
        <Splide options={{
          perPage: 1,
          perMove: 1,
          arrows: false,
          type: 'loop',
          autoplay: true,
          interval: 5000,
          pauseOnHover: true,
        }}>
          {data && data.map((slide) => (
            <SplideSlide key={slide.id}>
              <FeaturedSlide slide={slide} />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  )
}

export default FeaturedSlider