import { Routes, Route } from "react-router-dom"
import Layout from "./components/Layout/Layout"
import Index from "./views/Index"
import AuthView from "./views/AuthView"
import ProfileView from "./views/ProfileView"
import CartView from "./views/CartView"
import CatalogView from "./views/CatalogView"
import ProductView from "./views/ProductView"
import ShippingAndPaymentView from "./views/static/ShippingAndPaymentView"
import LoyaltySystemView from "./views/static/LoyaltySystemView"
import CoffeeShopsView from "./views/static/CoffeeShopsView"
import FaqView from "./views/static/FaqView"
import ContactsView from "./views/static/ContactsView"
import ReviewsView from "./views/ReviewsView"
import AboutUsView from "./views/static/AboutUsView"
import KnowledgeView from "./views/KnowledgeView"
import KnowledgeOneView from "./views/KnowledgeOneView"
import EducationView from "./views/EducationView"
import EducationOneView from "./views/EducationOneView"
import CoffeeDictionaryView from "./views/static/CoffeeDictionaryView"
import CountryView from "./views/CountryView"
import UserAgreementView from "./views/static/UserAgreementView"
import ThankYouView from "./views/static/ThankYouView"
import NotFoundView from "./views/NotFoundView"

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/register" element={<AuthView tab="register" />} />
        <Route path="/login" element={<AuthView tab="login" />} />
        <Route path="/me" element={<ProfileView />} />
        <Route path="/cart" element={<CartView />} />
        <Route path="/catalog/:slug" element={<CatalogView />} /> {/* TODO 404 */}
        <Route path="/products/:slug" element={<ProductView />} /> {/* TODO 404 */}

        <Route path="/shipping-and-payment" element={<ShippingAndPaymentView />} />
        <Route path="/loyalty-system" element={<LoyaltySystemView />} />
        <Route path="/coffee-shops" element={<CoffeeShopsView />} />
        <Route path="/faq" element={<FaqView />} />
        <Route path="/contacts" element={<ContactsView />} />
        <Route path="/reviews" element={<ReviewsView />} />
        <Route path="/about-us" element={<AboutUsView />} />
        <Route path="/knowledge" element={<KnowledgeView />} />
        <Route path="/knowledge/:slug" element={<KnowledgeOneView />} /> {/* TODO 404 */}
        <Route path="/education-all" element={<EducationView />} />
        <Route path="/education-all/:slug" element={<EducationOneView />} /> {/* TODO 404 */}
        <Route path="/coffee-dictionary" element={<CoffeeDictionaryView />} />
        <Route path="/country/:slug" element={<CountryView />} /> {/* TODO 404 */}

        <Route path="/user-agreement" element={<UserAgreementView />} />
        <Route path="/thank-you" element={<ThankYouView />} />
        
        <Route path="*" element={<NotFoundView />} /> {/* TODO 404 */}
      </Routes>
    </Layout>
  )
}

export default App
