import { useState, useCallback, useEffect } from 'react'
import { DELIVERY_TYPES, PAYMENT_TYPES, deliveryTypes, paymentTypes, SLUG_SETTING_RESTRICTED_DATES } from '../utils/enums'
import citiesDalli from '../data/citiesDalli.json'
import { dalliZones } from '../data/data'
import { getDeliveryDateVariants, getDeliveryPriceDiscount } from '../utils/functions'
import {
  getDeliveryPriceDalli, getDeliveryPriceCdek, getDeliveryPriceYandex, getDeliveryPriceRussianPost, storeOrder,
} from '../utils/api'
import { useGetUserMcrmQuery, useGetSettingsBySlugsQuery } from '../features/backend/backendApiSlice'

const useCartForm = (initialState, cartItemsTotalWeight, cartItemsTotalSum) => {
  if (new Date() > new Date(2024, 9, 27)) {
    return null
  }

  const { data: settings } = useGetSettingsBySlugsQuery([SLUG_SETTING_RESTRICTED_DATES])
  const restrictedDates = settings && settings[SLUG_SETTING_RESTRICTED_DATES] ? settings[SLUG_SETTING_RESTRICTED_DATES].map(date => new Date(date)) : []

  const [fields, setFields] = useState(initialState)
  const [invalidFields, setInvalidFields] = useState([])

  console.log(fields)

  const [deliveryPrice, setDeliveryPrice] = useState(null)
  const [deliveryPriceLoading, setDeliveryPriceLoading] = useState(null)

  const [deliveryPriceDalli, setDeliveryPriceDalli] = useState(null)
  const [deliveryPriceCdekCourier, setDeliveryPriceCdekCourier] = useState(null)
  const [deliveryPriceYandexCourier, setDeliveryPriceYandexCourier] = useState(null)
  const [deliveryPriceYandexPickup, setDeliveryPriceYandexPickup] = useState(null)
  const [deliveryPriceCdekPickup, setDeliveryPriceCdekPickup] = useState(null)
  const [deliveryPriceRussianPost, setDeliveryPriceRussianPost] = useState(null)

  const [deliveryTimeCdekCourierFrom, setDeliveryTimeCdekCourierFrom] = useState(null)
  const [deliveryTimeCdekCourierTo, setDeliveryTimeCdekCourierTo] = useState(null)
  const [deliveryTimeYandexCourierFrom, setDeliveryTimeYandexCourierFrom] = useState(null)
  const [deliveryTimeYandexCourierTo, setDeliveryTimeYandexCourierTo] = useState(null)
  const [deliveryTimeYandexPickupFrom, setDeliveryTimeYandexPickupFrom] = useState(null)
  const [deliveryTimeYandexPickupTo, setDeliveryTimeYandexPickupTo] = useState(null)
  const [deliveryTimeCdekPickupFrom, setDeliveryTimeCdekPickupFrom] = useState(null)
  const [deliveryTimeCdekPickupTo, setDeliveryTimeCdekPickupTo] = useState(null)
  const [deliveryTimeRussianPostFrom, setDeliveryTimeRussianPostFrom] = useState(null)
  const [deliveryTimeRussianPostTo, setDeliveryTimeRussianPostTo] = useState(null)

  const calculateDeliveryPriceDiscounted = (price) => {
    let priceDiscounted = price - getDeliveryPriceDiscount(fields.deliveryType, fields.city, cartItemsTotalSum)
    if (priceDiscounted < 0) priceDiscounted = 0
    return priceDiscounted
  }

  const calculateDeliveryPrice = (addressOrPoint = null) => {
    if (fields.deliveryType === DELIVERY_TYPES.COURIER_DALLI && addressOrPoint) {
      setDeliveryPriceLoading(true)
      getDeliveryPriceDalli(addressOrPoint, cartItemsTotalWeight).then((data) => {
        if (data.error) {
          setDeliveryPriceLoading(false)
        } else {
          setDeliveryPrice(calculateDeliveryPriceDiscounted(data.price))
          setDeliveryPriceLoading(null)
        }
      })
    }

    if (fields.deliveryType === DELIVERY_TYPES.COURIER_CDEK && addressOrPoint) {
      setDeliveryPriceLoading(true)
      getDeliveryPriceCdek('137', fields.countryIsoCode, fields.city, addressOrPoint, fields.cityPostalCode, cartItemsTotalWeight).then((data) => {
        if (data.error) {
          setDeliveryPriceLoading(false)
        } else {
          setDeliveryPrice(calculateDeliveryPriceDiscounted(data.price))
          setDeliveryPriceLoading(null)
        }
      })
    }

    if (fields.deliveryType === DELIVERY_TYPES.COURIER_YANDEX && addressOrPoint) {
      setDeliveryPriceLoading(true)
      getDeliveryPriceYandex('time_interval', addressOrPoint, '', cartItemsTotalWeight).then((data) => {
        if (data.error) {
          setDeliveryPriceLoading(false)
        } else {
          setDeliveryPrice(calculateDeliveryPriceDiscounted(data.price))
          setDeliveryPriceLoading(null)
        }
      })
    }

    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_STORE) {
      setDeliveryPrice(0)
      setDeliveryPriceLoading(null)
    }

    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_YANDEX && addressOrPoint) {
      setDeliveryPriceLoading(true)
      getDeliveryPriceYandex('self_pickup', '', addressOrPoint, cartItemsTotalWeight).then((data) => {
        if (data.error) {
          setDeliveryPriceLoading(false)
        } else {
          setDeliveryPrice(calculateDeliveryPriceDiscounted(data.price))
          setDeliveryPriceLoading(null)
        }
      })
    }

    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_CDEK && addressOrPoint) {
      setDeliveryPriceLoading(true)
      getDeliveryPriceCdek('136', fields.countryIsoCode, fields.city, addressOrPoint, fields.cityPostalCode, cartItemsTotalWeight).then((data) => {
        if (data.error) {
          setDeliveryPriceLoading(false)
        } else {
          setDeliveryPrice(calculateDeliveryPriceDiscounted(data.price))
          setDeliveryPriceLoading(null)
        }
      })
    }

    if (fields.deliveryType === DELIVERY_TYPES.RUSSIAN_POST) {
      setDeliveryPriceLoading(true)
      getDeliveryPriceRussianPost(fields.cityFias, cartItemsTotalWeight).then((data) => {
        if (data.error) {
          setDeliveryPriceLoading(false)
        } else {
          setDeliveryPrice(calculateDeliveryPriceDiscounted(data.price))
          setDeliveryPriceLoading(null)
        }
      })
    }
  }

  // TODO remove useEffect here?
  useEffect(() => {
    if ([ DELIVERY_TYPES.COURIER_DALLI, DELIVERY_TYPES.COURIER_CDEK, DELIVERY_TYPES.COURIER_YANDEX, DELIVERY_TYPES.RUSSIAN_POST ].includes(fields.deliveryType) && fields.address) calculateDeliveryPrice(fields.address)
    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_STORE) calculateDeliveryPrice()
    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_YANDEX && fields.pickupYandexId) calculateDeliveryPrice(fields.pickupYandexId)
    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_CDEK && fields.pickupCdekId) calculateDeliveryPrice(fields.pickupCdekId)
  }, [fields.deliveryType, fields.address, fields.pickupYandexId, fields.pickupCdekId, cartItemsTotalWeight, cartItemsTotalSum])

  const setField = (name) => (value) => setFields((prevState) => ({ ...prevState, [name]: value }))

  const setFieldMemoized = (name) => useCallback((value) => setFields((prevState) => ({ ...prevState, [name]: value })), [])

  const setFieldMultiple = (data) => setFields((prevState) => ({ ...prevState, ...data }))

  const setFieldMultipleMemoized = useCallback((data) => setFields((prevState) => ({ ...prevState, ...data })), [])

  const setCity = (country, countryIsoCode, region, city, cityFias, cityKladrId, cityPostalCode, address = {}) => {
    let deliveryTypeVariants = []
    let deliveryDateVariants = getDeliveryDateVariants(restrictedDates)
    let deliveryTimeVariants = []

    if (country == "Россия") {
      if (city == "Москва") {
        deliveryTypeVariants = [
          DELIVERY_TYPES.COURIER_DALLI,
          DELIVERY_TYPES.COURIER_YANDEX,
          DELIVERY_TYPES.PICKUP_STORE,
          DELIVERY_TYPES.PICKUP_YANDEX,
          DELIVERY_TYPES.PICKUP_CDEK,
          DELIVERY_TYPES.RUSSIAN_POST,
        ]
      } else if (citiesDalli.includes(city)) {
        deliveryTypeVariants = [
          DELIVERY_TYPES.COURIER_DALLI,
          DELIVERY_TYPES.COURIER_YANDEX,
          DELIVERY_TYPES.PICKUP_YANDEX,
          DELIVERY_TYPES.PICKUP_CDEK,
          DELIVERY_TYPES.RUSSIAN_POST,
        ]
      } else {
        deliveryTypeVariants = [
          DELIVERY_TYPES.COURIER_CDEK,
          DELIVERY_TYPES.COURIER_YANDEX,
          DELIVERY_TYPES.PICKUP_YANDEX,
          DELIVERY_TYPES.PICKUP_CDEK,
          DELIVERY_TYPES.RUSSIAN_POST,
        ]
      }

      // if (city == "Москва" || city == "Санкт-Петербург" || region == "Московская") {
      //   deliveryTimeVariants = [
      //     "09:00 - 18:00",
      //     "18:00 - 22:00",
      //     "09:00 - 22:00",
      //   ]
      // } else {
      //   deliveryTimeVariants = [
      //     "09:00 - 13:00",
      //     "10:00 - 22:00",
      //     "10:00 - 17:00",
      //     "18:00 - 22:00",
      //   ]
      // }

      if (citiesDalli.includes(city)) {
        const dalliZone = dalliZones.find((elem) => elem.cities?.includes(city) || elem.regions?.includes(region)) ?? dalliZones.find((elem) => elem.default)
        deliveryTimeVariants = dalliZone.options
      } else {
        deliveryTimeVariants = []
      }
    } else {
      deliveryTypeVariants = [
        DELIVERY_TYPES.PICKUP_CDEK,
        // DELIVERY_TYPES.RUSSIAN_POST,
      ]
    }

    setFieldMultiple({
      country: country,
      countryIsoCode: countryIsoCode,
      region: region,
      city: city,
      cityFias: cityFias,
      cityKladrId: cityKladrId,
      cityPostalCode: cityPostalCode,
      deliveryTypeVariants: deliveryTypeVariants,
      deliveryType: deliveryTypeVariants[0],
      deliveryDateVariants: deliveryDateVariants,
      deliveryDate: deliveryDateVariants[0].toLocaleDateString("ru-RU"),
      deliveryTimeVariants: deliveryTimeVariants,
      deliveryTime: deliveryTimeVariants[0],
      address: address?.address || null,
      entrance: address?.entrance || null,
      floor: address?.floor || null,
      apartment: address?.apartment || null,
      intercom: address?.intercom || null,
      pickupStore: null,
      pickupYandex: null,
      pickupCdek: null,
    })

    setDeliveryPriceDalli(null)
    setDeliveryPriceCdekCourier(null)
    setDeliveryPriceYandexCourier(null)
    setDeliveryPriceYandexPickup(null)
    setDeliveryPriceCdekPickup(null)
    setDeliveryPriceRussianPost(null)

    setDeliveryTimeCdekCourierFrom(null)
    setDeliveryTimeCdekCourierTo(null)
    setDeliveryTimeYandexCourierFrom(null)
    setDeliveryTimeYandexCourierTo(null)
    setDeliveryTimeYandexPickupFrom(null)
    setDeliveryTimeYandexPickupTo(null)
    setDeliveryTimeCdekPickupFrom(null)
    setDeliveryTimeCdekPickupTo(null)
    setDeliveryTimeRussianPostFrom(null)
    setDeliveryTimeRussianPostTo(null)

    setDeliveryPrice(null)
    setDeliveryPriceLoading(null)

    const cartItemsTotalWeight = 1 // TODO change, or maybe not since we're just calculating base values here anyway

    getDeliveryPriceDalli(city, cartItemsTotalWeight).then((data) => {
      if (data.error) {
        setDeliveryPriceDalli(false)
      } else {
        setDeliveryPriceDalli(data.price)
      }
    })

    getDeliveryPriceCdek('137', countryIsoCode, city, city, cityPostalCode, cartItemsTotalWeight).then((data) => {
      if (data.error) {
        setDeliveryPriceCdekCourier(false)
        setDeliveryTimeCdekCourierFrom(false)
        setDeliveryTimeCdekCourierTo(false)
      } else {
        setDeliveryPriceCdekCourier(data.price)
        setDeliveryTimeCdekCourierFrom(data.delivery_from)
        setDeliveryTimeCdekCourierTo(data.delivery_to)
      }
    })

    getDeliveryPriceYandex('time_interval', city, '', cartItemsTotalWeight).then((data) => {
      if (data.error) {
        setDeliveryPriceYandexCourier(false)
        setDeliveryTimeYandexCourierFrom(false)
        setDeliveryTimeYandexCourierTo(false)
      } else {
        setDeliveryPriceYandexCourier(data.price)
        setDeliveryTimeYandexCourierFrom(data.delivery_from)
        setDeliveryTimeYandexCourierTo(data.delivery_to)
      }
    })

    getDeliveryPriceCdek('136', countryIsoCode, city, city, cityPostalCode, cartItemsTotalWeight).then((data) => {
      if (data.error) {
        setDeliveryPriceCdekPickup(false)
        setDeliveryTimeCdekPickupFrom(false)
        setDeliveryTimeCdekPickupTo(false)
      } else {
        setDeliveryPriceCdekPickup(data.price)
        setDeliveryTimeCdekPickupFrom(data.delivery_from)
        setDeliveryTimeCdekPickupTo(data.delivery_to)
      }
    })

    getDeliveryPriceRussianPost(cityFias, cartItemsTotalWeight).then((data) => {
      if (data.error) {
        setDeliveryPriceRussianPost(false)
        setDeliveryTimeRussianPostFrom(false)
        setDeliveryTimeRussianPostTo(false)
      } else {
        setDeliveryPriceRussianPost(data.price)
        setDeliveryTimeRussianPostFrom(data.delivery_from)
        setDeliveryTimeRussianPostTo(data.delivery_to)
      }
    })
  }

  const setAddress = (address) => {
    setCity(address.country, address.country_iso_code, address.region, address.city, address.city_fias, address.city_kladr_id, address.city_postal_code, {
      address: address.address,
      entrance: address.entrance,
      floor: address.floor,
      apartment: address.apartment,
      intercom: address.intercom,
    })
  }

  const setFieldIsInvalid = (name, isInvalid) => setInvalidFields((prevState) => ({...prevState, [name]: isInvalid}))

  const validateFieldMemoized = (name) => useCallback((value) => {
    if (name == 'surname') setFieldIsInvalid(name, (value?.length < 1))
    if (name == 'name') setFieldIsInvalid(name, (value?.length < 1))
    if (name == 'phone') setFieldIsInvalid(name, (value.includes("_")))
    if (name == 'email') setFieldIsInvalid(name, (value?.length < 1 || !value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)))
    if (name == 'surnameReceiver') setFieldIsInvalid(name, (value?.length < 1))
    if (name == 'nameReceiver') setFieldIsInvalid(name, (value?.length < 1))
    if (name == 'phoneReceiver') setFieldIsInvalid(name, (value.includes("_")))
    if (name == 'emailReceiver') setFieldIsInvalid(name, (value?.length < 1 || !value.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)))
    if (name == 'city') setFieldIsInvalid(name, (value?.length < 1))
    if (name == 'address') setFieldIsInvalid(name, ([ DELIVERY_TYPES.COURIER_DALLI, DELIVERY_TYPES.COURIER_CDEK, DELIVERY_TYPES.COURIER_YANDEX, DELIVERY_TYPES.RUSSIAN_POST ].includes(fields.deliveryType) && value?.length < 1))
    if (name == 'pickupStore') setFieldIsInvalid(name, (fields.deliveryType === DELIVERY_TYPES.PICKUP_STORE && value?.length < 1))
    if (name == 'pickupYandex') setFieldIsInvalid(name, (fields.deliveryType === DELIVERY_TYPES.PICKUP_YANDEX && value?.length < 1))
    if (name == 'pickupCdek') setFieldIsInvalid(name, (fields.deliveryType === DELIVERY_TYPES.PICKUP_CDEK && value?.length < 1))
    if (name == 'paymentWireAcc') setFieldIsInvalid(name, (fields.paymentType === PAYMENT_TYPES.WIRE && value?.length < 1))
    if (name == 'paymentWireBik') setFieldIsInvalid(name, (fields.paymentType === PAYMENT_TYPES.WIRE && value?.length < 1))
    if (name == 'paymentWireCorrAcc') setFieldIsInvalid(name, (fields.paymentType === PAYMENT_TYPES.WIRE && value?.length < 1))
    if (name == 'paymentWireInn') setFieldIsInvalid(name, (fields.paymentType === PAYMENT_TYPES.WIRE && value?.length < 1))
    if (name == 'paymentWireKpp') setFieldIsInvalid(name, (fields.paymentType === PAYMENT_TYPES.WIRE && value?.length < 1))
    if (name == 'paymentWireOrg') setFieldIsInvalid(name, (fields.paymentType === PAYMENT_TYPES.WIRE && value?.length < 1))
    if (name == 'acceptTerms') setFieldIsInvalid(name, (value != true))
  }, [fields.deliveryType, fields.paymentType])

  const validateForm = () => {
    let invalidFields = {}

    if (!fields.surname) invalidFields.surname = true
    if (!fields.name) invalidFields.name = true
    if (fields.phoneNumber.includes("_")) invalidFields.phone = true
    if (!fields.email || !fields.email.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)) invalidFields.email = true

    if (fields.isForAnotherPerson === true) {
      if (!fields.surnameReceiver) invalidFields.surnameReceiver = true
      if (!fields.nameReceiver) invalidFields.nameReceiver = true
      if (fields.phoneNumberReceiver.includes("_")) invalidFields.phoneReceiver = true
      if (!fields.emailReceiver || !fields.emailReceiver.match(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)) invalidFields.emailReceiver = true
    }

    if (!fields.city) invalidFields.city = true

    if (([ DELIVERY_TYPES.COURIER_DALLI, DELIVERY_TYPES.COURIER_CDEK, DELIVERY_TYPES.COURIER_YANDEX, DELIVERY_TYPES.RUSSIAN_POST ].includes(fields.deliveryType)) && (!fields.address)) invalidFields.address = true

    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_STORE && !fields.pickupStore) invalidFields.pickupStore = true
    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_YANDEX && !fields.pickupYandex) invalidFields.pickupYandex = true
    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_CDEK && !fields.pickupCdek) invalidFields.pickupCdek = true

    if (fields.paymentType === PAYMENT_TYPES.WIRE) {
      if (!fields.paymentWireAcc) invalidFields.paymentWireAcc = true
      if (!fields.paymentWireBik) invalidFields.paymentWireBik = true
      if (!fields.paymentWireCorrAcc) invalidFields.paymentWireCorrAcc = true
      if (!fields.paymentWireInn) invalidFields.paymentWireInn = true
      if (!fields.paymentWireKpp) invalidFields.paymentWireKpp = true
      if (!fields.paymentWireOrg) invalidFields.paymentWireOrg = true
    }
    
    if (!fields.acceptTerms) invalidFields.acceptTerms = true

    setInvalidFields(invalidFields)

    return invalidFields
  }

  const composeData = (cartItems, cartTotalSum) => {
    const data = {
      surname: fields.surname,
      name: fields.name,
      phoneCode: fields.phoneCode,
      phoneNumber: fields.phoneNumber,
      email: fields.email,
      isForAnotherPerson: fields.isForAnotherPerson,
      surnameReceiver: fields.surnameReceiver,
      nameReceiver: fields.nameReceiver,
      phoneCodeReceiver: fields.phoneCodeReceiver,
      phoneNumberReceiver: fields.phoneNumberReceiver,
      emailReceiver: fields.emailReceiver,
      countryIsoCode: fields.countryIsoCode,
      country: fields.country,
      city: fields.city,
      deliveryType: deliveryTypes.find((elem) => elem.type === fields.deliveryType).slug,
      paymentType: paymentTypes.find((elem) => elem.type === fields.paymentType).slug,
      callForConfirmation: fields.callForConfirmation,
      comments: fields.comments,
    }

    if (fields.deliveryType === DELIVERY_TYPES.COURIER_DALLI) {
      data.deliveryDate = fields.deliveryDate
      data.deliveryTime = fields.deliveryTime
    }
    
    if ([ DELIVERY_TYPES.COURIER_DALLI, DELIVERY_TYPES.COURIER_CDEK, DELIVERY_TYPES.COURIER_YANDEX, DELIVERY_TYPES.RUSSIAN_POST ].includes(fields.deliveryType)) {
      data.address = fields.address
      data.entrance = fields.entrance
      data.floor = fields.floor
      data.apartment = fields.apartment
      data.intercom = fields.intercom
    }

    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_STORE) {
      data.pickupStore = fields.pickupStore
    }

    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_YANDEX) {
      data.pickupYandex = fields.pickupYandex
    }

    if (fields.deliveryType === DELIVERY_TYPES.PICKUP_CDEK) {
      data.pickupCdek = fields.pickupCdek
    }

    if (fields.paymentType === PAYMENT_TYPES.WIRE) {
      data.paymentWireAcc = fields.paymentWireAcc
      data.paymentWireBik = fields.paymentWireBik
      data.paymentWireCorrAcc = fields.paymentWireCorrAcc
      data.paymentWireInn = fields.paymentWireInn
      data.paymentWireKpp = fields.paymentWireKpp
      data.paymentWireOrg = fields.paymentWireOrg
    }

    if (fields.certificate && fields.certificateBalance > 0 && fields.certificateDiscount > 0) {
      data.certificate = fields.certificate
      data.certificateBalance = fields.certificateBalance
      data.certificateDiscount = fields.certificateDiscount
    }

    if (fields.coupon && fields.couponDiscount > 0) {
      data.coupon = fields.coupon
      data.couponDiscount = fields.couponDiscount
    }

    const token_mcrm = localStorage.getItem('token_mcrm')

    if (token_mcrm && fields.bonusesDiscount > 0) {
      data.token_mcrm = token_mcrm
      data.bonusesDiscount = fields.bonusesDiscount
    }

    data.cartItems = cartItems
    data.deliveryPrice = deliveryPrice
    data.cartTotalSum = cartTotalSum

    return data
  }

  const submitOrder = async (cartItems, cartTotalSum) => {
    if (fields?.isSubmitting === true) return

    const invalidFields = validateForm()
    if (Object.keys(invalidFields).length > 0) {
      setField('showErrors')(true)
      return
    }
    
    setFieldMultiple({
      showErrors: false,
      isSubmitting: true,
    })
    const data = composeData(cartItems, cartTotalSum)

    console.log(data)

    const response = await storeOrder(data)

    console.log(response)

    return response
  }

  return {
    fields,
    invalidFields,
    validateFieldMemoized,
    setField,
    setFieldMemoized,
    setFieldMultiple,
    setFieldMultipleMemoized,
    setCity,
    setAddress,
    submitOrder,

    deliveryPrice,
    deliveryPriceLoading,
    setDeliveryPrice,
    setDeliveryPriceLoading,

    deliveryPriceDalli,
    deliveryPriceCdekCourier,
    deliveryPriceYandexCourier,
    deliveryPriceYandexPickup,
    setDeliveryPriceYandexPickup,
    deliveryPriceCdekPickup,
    deliveryPriceRussianPost,

    deliveryTimeCdekCourierFrom,
    deliveryTimeCdekCourierTo,
    deliveryTimeYandexCourierFrom,
    deliveryTimeYandexCourierTo,
    deliveryTimeYandexPickupFrom,
    deliveryTimeYandexPickupTo,
    setDeliveryTimeYandexPickupFrom,
    setDeliveryTimeYandexPickupTo,
    deliveryTimeCdekPickupFrom,
    deliveryTimeCdekPickupTo,
    deliveryTimeRussianPostFrom,
    deliveryTimeRussianPostTo,
  }
}

export default useCartForm